import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {IRole} from '../model/role';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {IProfil} from '../model/profil';
import {URLS} from '../utils/contants';
import {IEntite} from '../model/entite';
import {NotificationService} from './notification.service';
import {clearLoginLocalStorage, createRequestOption} from '../utils/utils';
import {IDashboard} from '../model/IDashboard';
import {UpdatePasword} from '../model/update-password';
import {ILog} from '../model/yl-log';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {IUser} from '../model/user';
import {IParametre} from '../model/parametre';
import {ReportInput} from '../model/report-input';
import {IGroupe} from '../model/groupe';
import {ICategory} from '../model/categorie';
import {ILocalite} from '../model/localite';
import {IProduit} from '../model/produit';
import {IClient} from '../model/client';
import {IReservation} from '../model/reservation';
import {ILocation} from '../model/location';
import {IProfession} from '../model/profession';
import {ILocataire} from '../model/locataire';
import {IProprietaire} from '../model/proprietaire';
import {IPhoto} from '../model/photo';

@Injectable({
    providedIn: 'root'
})
export class ImmobService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient,
                private $localStorage: LocalStorageService,
                private $sessionStorage: SessionStorageService,
                private notificationService: NotificationService) {
    }

    /**
     * Récupérer la liste des rôles.
     */
    fetchRoles() {
        if (isDevMode()) {
            console.log('Fetch roles in dev mode');
        } else {
            console.log('Fetch roles in production mode');
        }
        return this.http.get<IRole[]>(URLS.ROLE)
            .pipe(
                catchError(this.handleError<IRole[]>('fetchRoles', []))
            );
    }


    /**
     * Récupérer la liste des profils.
     */
    fetchProfils() {
        if (isDevMode()) {
            console.log('Fetch profils in dev mode');
            console.log('header dev mode', this.httpOptions.headers);
        } else {
            console.log('Fetch profils in production mode');
        }
        return this.http.get<IProfil[]>(URLS.PROFIL)
            .pipe(
                catchError(this.handleError<IProfil[]>('fetchProfils', []))
            );
    }

    /**
     * Modifier un profil.
     * @param item l'objet concerné
     */
    putProfil(item: IProfil) {
        return this.http.put<IProfil>(URLS.PROFIL, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Profil modifié avec succès');
                }),
                catchError(this.handleError<IProfil>('putProfil', {}))
            );
    }

    /**
     * Enregistrer un profil.
     * @param item l'objet concerné
     */
    postProfil(item: IProfil) {
        return this.http.post<IProfil>(URLS.PROFIL, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Enregistrement', 'Profil enregistré avec succès');
                }),
                catchError(this.handleError<IProfil>('postProfil', {}))
            );
    }

    /**
     * Supprimer un profil.
     * @param item l'objet concerné
     */
    deleteProfil(item: IProfil) {
        return this.http.delete<string>(`${URLS.PROFIL}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Suppression', 'Profil supprimé avec succès');
                }),
                catchError(this.handleError<string>('deleteProfil', ''))
            );
    }

    /**
     * Supprimer un rôles.
     * @param item l'objet concerné
     */
    deleteRole(item: IRole) {
        return this.http.delete<string>(`${URLS.ROLE}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Suppression', 'Rôle supprimé avec succès');
                }),
                catchError(this.handleError<string>('deleteRole', ''))
            );
    }

    /**
     * Modifier un role.
     * @param item l'objet concerné
     */
    putRole(item: IRole) {
        return this.http.put<IRole>(URLS.ROLE, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Rôle modifié avec succès');
                }),
                catchError(this.handleError<IRole>('putRole', {}))
            );
    }

    /**
     * Enregistrer un role.
     * @param item l'objet concerné
     */
    postRole(item: IRole) {
        return this.http.post<IRole>(URLS.ROLE, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Enregistrement', 'Rôle enregistré avec succès');
                }),
                catchError(this.handleError<IRole>('postRole', {}))
            );
    }


    /**
     * Récupérer la liste des entités.
     */
    fetchEntites(filtre?: any) {
        return this.http.get<IEntite[]>(URLS.ENTITE, {params: createRequestOption(filtre)})
            .pipe(
                catchError(this.handleError<IEntite[]>('fetchEntites', []))
            );
    }


    /**
     * Enregistrer une entité.
     * @param item l'objet concerné
     */
    postEntite(item: IEntite) {
        return this.http.post<IEntite>(URLS.ENTITE, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Enregistrement', 'Entité enregistrée avec succès');
                }),
                catchError(this.handleError<IEntite>('postEntite', {}))
            );
    }

    /**
     * Modifier une entité.
     * @param item l'objet concerné
     */
    putEntite(item: IEntite) {
        return this.http.put<IEntite>(`${URLS.ENTITE}/${item.id}`, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Entité modifiée avec succès');
                }),
                catchError(this.handleError<IEntite>('putEntite', {}))
            );
    }

    /**
     * Supprimer une entité.
     * @param item l'objet concerné
     */
    deleteEntite(item: IEntite) {
        return this.http.delete<string>(`${URLS.ENTITE}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Suppression', 'Entité supprimée avec succès');
                }),
                catchError(this.handleError<string>('deleteEntite', ''))
            );
    }

    /**
     * Récupérer les infos du dashboard.
     */
    fetchDashboard() {
        return this.http.get<IDashboard>(`${URLS.DASHBOARD}`).pipe(
            catchError(this.handleError<IDashboard>('fetchDashboard', null))
        );
    }

    fetchInfosCompte() {
        return this.http.get<IUser>(URLS.CURRENT_USER_INFO)
            .pipe(
                catchError(this.handleError<IUser>('fetchInfosCompte' ))
            );
    }



    /**
     * Lancer une édition d'un état.
     * @param item l'objet concerné
     */
    reporting(item: ReportInput) {
        return this.http.post(URLS.REPORTING, item, {responseType: 'blob'})
            .pipe(
                catchError(this.handleError<any>('reporting', {}))
            );
    }


    /**
     * Récupérer les informations du user.
     */
    fetchUserInfos(filtre = {}) {
        return this.http.get<IUser[]>(URLS.CURRENT_USER_INFO, {params: createRequestOption(filtre)})
            .pipe(
                catchError(this.handleError<IUser[]>('fetchUserInfos', []))
            );
    }


    /**
     * Récupérer la liste des users.
     */
    fetchUsers(filtre = {}) {
        return this.http.get<IUser[]>(URLS.USER, {params: createRequestOption(filtre)})
            .pipe(
                catchError(this.handleError<IUser[]>('fetchUsers', []))
            );
    }


    /**
     * Enregistrer un user.
     * @param item l'objet concerné
     */
    postUser(item: IUser) {
        return this.http.post<IUser>(URLS.USER, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Enregistrement', 'Utilisateur enregistré avec succès');
                }),
                catchError(this.handleError<IUser>('postUser', {}))
            );
    }

    /**
     * Modifier un user.
     * @param item l'objet concerné
     */
    putUser(item: IUser) {
        return this.http.put<IUser>(`${URLS.USER}/${item.id}`, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Utilisateur modifié avec succès');
                }),
                catchError(this.handleError<any>('putUser', {}))
            );
    }
/**
     * Modifier un user.
     * @param item l'objet concerné
     */
    putUserPassword(item: UpdatePasword) {
        return this.http.put<IUser>(URLS.USER + '/change-password', item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Mot de passe modifié avec succès');
                }),
                catchError(this.handleError<any>('putUserPassword', {}))
            );
    }

    /**
     * Supprimer un user.
     * @param item l'objet concerné
     */
    deleteUser(item: IUser) {
        return this.http.delete<string>(`${URLS.USER}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Suppression', 'Utilisateur supprimé avec succès');
                }),
                catchError(this.handleError<string>('deleteUser', ''))
            );
    }


    /**
     * Achever la réinitialisation du mot de passe.
     * @param item l'objet concerné
     */
    completResetPassword(item: UpdatePasword) {
        return this.http.put<any>(`${URLS.USER}/complete-reset-password`, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Activation', 'Compte activé avec succès.\nVeuillez vous reconnecter');
                }),
                catchError(this.handleError<UpdatePasword>('completResetPassword', {}))
            );
    }


    /**
     * Réinitialisation de mot de passe.
     * @param item l'objet concerné
     */
    resetPassword(item: UpdatePasword) {
        return this.http.put<any>(`${URLS.USER}/change-password`, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Mot de passe', 'Mot de passe changé avec succès.\nVeuillez vous reconnecter');
                }),
                catchError(this.handleError<UpdatePasword>('resetPassword', {}))
            );
    }


    /**
     * Demander l'envoi du code d'activation.
     * @param item l'objet concerné
     */
    resendActivationCode(item: any) {
        return this.http.put<any>(`${URLS.USER}/request-reset-password`, item)
            .pipe(
                tap(i => {
                    this.notificationService.success('Activation', 'Code envoyé avec succès');
                }),
                catchError(this.handleError<any>('resendActivationCode'))
            );
    }


    /**
     * Vérifier si le compte est activé ou pas.
     */
    checkActivation(username: string) {
        return this.http.get<boolean>(`${URLS.USER}/${username}/is-activated`)
            .pipe(
                catchError(this.handleError<boolean>('checkActivation', false))
            );
    }

    /**
     * Récupérer la liste des logs.
     */
    fetchLogs(filtre = {}) {
        return this.http.get<ILog[]>(URLS.LOG, {params: createRequestOption(filtre)})
            .pipe(
                catchError(this.handleError<ILog[]>('fetchLogs', []))
            );
    }

    getYears() {

        // Initialiser la liste des années pour le rapport.
        // Il peut choisir 100 ans en arrière.
        const anneeActuelle = new Date().getFullYear();
        const yrs = [anneeActuelle];
        for (let i = 1; i <= 90; i++) {
            yrs.push(anneeActuelle - i);
        }
        return yrs;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (err: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(err); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.error(`${operation} failed: ${err.message}`);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    clearLoginLocalStorage(this.$localStorage, this.$sessionStorage);
                }
                if (err.status === 400) {
                    if (err.error) {
                        this.notificationService.error('Erreur de données', err.error.message);
                    } else {
                        this.notificationService.error('Erreur de données', 'Les informations envoyées sont invalides.');
                    }
                } else if (err.status === 500) {
                    this.notificationService.error('Erreur interne', 'Nous avons rencontré une erreur interne. ' +
                        'L\'opération a échouée.');
                } else if (err.status === 504) {
                    this.notificationService.error('Erreur de connexion', 'Le serveur est injoignable.');
                } else {
                    this.notificationService.error('Erreur inconnue', 'Nous avons rencontré une erreur inconnue.');
                }
            }
            // Let the app keep running by returning an empty result.
            // return of(result as T);
            throw err;
        };
    }

    /**
     * Enregistrer une groupe.
     * @param item l'objet concerné
     */
    postGroupe(item: IGroupe) {
        return this.http.post<IGroupe>(URLS.GROUPE, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Enregistrement', 'Groupe enregistré avec succès');
                }),
                catchError(this.handleError<IGroupe>('postGroupe', {}))
            );
    }

    /**
     * Modifier un groupe.
     * @param item l'objet concerné
     */
    putGroupe(item: IGroupe) {
        return this.http.put<IGroupe>(`${URLS.GROUPE}/${item.id}`, item, this.httpOptions)
            .pipe(
                tap(i => {
                    this.notificationService.success('Modification', 'Groupe modifié avec succès');
                }),
                catchError(this.handleError<IGroupe>('putGroupe', {}))
            );
    }

    /**
     * Supprimer un groupe
     * @param item l'objet concerné
     */
    deleteGroupe(item: IGroupe) {
        return this.http.delete<string>(`${URLS.GROUPE}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Suppression', 'Groupe supprimé avec succès');
                }),
                catchError(this.handleError<string>('deleteGroupe', ''))
            );
    }

    /**
     * Récupérer la liste des infos du dashboard.
     */
    fetchDashboardInfos() {
            return this.http.get<IDashboard[]>(URLS.DASHBOARD_INFOS)
                .pipe(
                    catchError(this.handleError<IDashboard[]>('fetchDashboardInfos', []))
                );
    }
    /**
     * Publier sur le site.
     * @param item l'objet concerné
     */
    publierSurSite(item: IDashboard) {
        return this.http.delete<string>(`${URLS.DASHBOARD_INFOS}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Publication', 'Information publié avec succès');
                }),
                catchError(this.handleError<string>('publierSurSite', ''))
            );
    }

    /**
     * Supprimer une publication sur le site.
     * @param item l'objet concerné
     */
    supprimerSurSite(item: IDashboard) {
        return this.http.delete<string>(`${URLS.DASHBOARD_INFOS_SUP}/${item.id}`, {responseType: 'text' as 'json'})
            .pipe(
                tap(i => {
                    this.notificationService.success('Publication', 'Information supprimé avec succès sur le site');
                }),
                catchError(this.handleError<string>('supprimerSurSite', ''))
            );
    }


  /**
   * Récupérer la liste des Categorys.
   */
  fetchCategories() {
    return this.http.get<ICategory[]>(URLS.CATEGORY)
      .pipe(
        catchError(this.handleError<ICategory[]>('fetchCategories', []))
      );
  }


  /**
   * Récupérer la liste des Categories.
   */
  fetchCategorieMaisons() {
    return this.http.get<ICategory[]>(URLS.CATEGORY_MAISON)
      .pipe(
        catchError(this.handleError<ICategory[]>('fetchCategorie_maisons', []))
      );
  }
/**
   * Récupérer la liste des Categories.
   */
  fetchCategorieVoitures() {
    return this.http.get<ICategory[]>(URLS.CATEGORY_VOITURE)
      .pipe(
        catchError(this.handleError<ICategory[]>('fetchCategorie_voitures', []))
      );
  }


  /**
   * Enregistrer un Category.
   * @param item l'objet concerné
   */
  postCategory(item: ICategory) {
    return this.http.post<ICategory>(URLS.CATEGORY, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Category enregistré avec succès')
        }),
        catchError(this.handleError<ICategory>('postCategory', {}))
      );
  }

  /**
   * Modifier un Category.
   * @param item l'objet concerné
   */
  putCategory(item: ICategory) {
    return this.http.put<ICategory>(`${URLS.CATEGORY}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Category modifié avec succès')
        }),
        catchError(this.handleError<ICategory>('putCategory', {}))
      );
  }

  /**
   * Supprimer un Category.
   * @param item l'objet concerné
   */
  deleteCategory(item: ICategory) {
    return this.http.delete<string>(`${URLS.CATEGORY}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Category supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteCategory', ''))
      );
  }
/**
   * Récupérer la liste des localites.
   */
  fetchLocalites() {
    return this.http.get<ILocalite[]>(URLS.LOCALITE)
      .pipe(
        catchError(this.handleError<ILocalite[]>('fetchLocalites', []))
      );
  }


  /**
   * Enregistrer un Localite.
   * @param item l'objet concerné
   */
  postLocalites(item: ILocalite) {
    return this.http.post<ILocalite>(URLS.LOCALITE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Localite enregistré avec succès')
        }),
        catchError(this.handleError<ILocalite>('postLocalite', {}))
      );
  }

  /**
   * Modifier un Localite.
   * @param item l'objet concerné
   */
  putLocalite(item: ILocalite) {
    return this.http.put<ILocalite>(`${URLS.LOCALITE}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Localite modifié avec succès')
        }),
        catchError(this.handleError<ILocalite>('putLocalite', {}))
      );
  }

  /**
   * Supprimer un Localite.
   * @param item l'objet concerné
   */
  deleteLocalite(item: ILocalite) {
    return this.http.delete<string>(`${URLS.LOCALITE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Localite supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteLocalite', ''))
      );
  }

/**
   * Récupérer la liste des Maisons.
   */
  fetchMaisons() {
    let id = 'MAISON';
    return this.http.get<IProduit[]>(URLS.PRODUIT)
      .pipe(
        catchError(this.handleError<IProduit[]>('fetchMaisons', []))
      );
  }


  /**
   * Enregistrer un Maison.
   * @param item l'objet concerné
   */
  postMaison(item: IProduit) {
    return this.http.post<IProduit>(URLS.PRODUIT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Maison enregistré avec succès')
        }),
        catchError(this.handleError<IProduit>('postMaison', {}))
      );
  }

  /**
   * Modifier une Maison.
   * @param item l'objet concerné
   */
  putMaison(item: IProduit) {
    return this.http.put<IProduit>(URLS.PRODUIT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Maison modifié avec succès')
        }),
        catchError(this.handleError<IProduit>('putMaison', {}))
      );
  }

  /**
   * Supprimer une Maison.
   * @param item l'objet concerné
   */
  deleteMaison(item: IProduit) {
    return this.http.delete<string>(`${URLS.PRODUIT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Maison supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteMaison', ''))
      );
  }

  /**
   * Récupérer la liste des Voitures.
   */
  fetchVoitures() {
    return this.http.get<IProduit[]>(URLS.PRODUCT)
      .pipe(
        catchError(this.handleError<IProduit[]>('fetchVoitures', []))
      );
  }


  /**
   * Enregistrer un Voiture.
   * @param item l'objet concerné
   */
  postVoiture(item: IProduit) {
    return this.http.post<IProduit>(URLS.PRODUCT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Voiture enregistré avec succès')
        }),
        catchError(this.handleError<IProduit>('postVoiture', {}))
      );
  }

  /**
   * Modifier une Voiture.
   * @param item l'objet concerné
   */
  putVoiture(item: IProduit) {
    return this.http.put<IProduit>(URLS.PRODUIT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Voiture modifié avec succès')
        }),
        catchError(this.handleError<IProduit>('putVoiture', {}))
      );
  }

  /**
   * Supprimer une Voiture.
   * @param item l'objet concerné
   */
  deleteVoiture(item: IProduit) {
    return this.http.delete<string>(`${URLS.PRODUIT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Voiture supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteVoiture', ''))
      );
  }

  /**
   * Récupérer la liste des Locations.
   */
  fetchLocations() {
    return this.http.get<ILocation[]>(URLS.LOCATION)
      .pipe(
        catchError(this.handleError<ILocation[]>('fetchLocations', []))
      );
  }


  /**
   * Enregistrer un Location.
   * @param item l'objet concerné
   */
  postLocation(item: ILocation) {
    return this.http.post<ILocation>(URLS.LOCATION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Location enregistré avec succès')
        }),
        catchError(this.handleError<ILocation>('postLocation', {}))
      );
  }

  /**
   * Modifier une Location.
   * @param item l'objet concerné
   */
  putLocation(item: ILocation) {
    return this.http.put<ILocation>(`${URLS.LOCATION}/${item.id}`, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Location modifié avec succès')
        }),
        catchError(this.handleError<ILocation>('putLocation', {}))
      );
  }

  /**
   * Supprimer une Location.
   * @param item l'objet concerné
   */
  deleteLocation(item: ILocation) {
    return this.http.delete<string>(`${URLS.LOCATION}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Location supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteLocation', ''))
      );
  }

  /**
   * Récupérer la liste des Reservations.
   */
  fetchReservations() {
    return this.http.get<IReservation[]>(URLS.RESERVATION)
      .pipe(
        catchError(this.handleError<IReservation[]>('fetchReservations', []))
      );
  }


  /**
   * Enregistrer un Reservation.
   * @param item l'objet concerné
   */
  postReservation(item: IReservation) {
    return this.http.post<IReservation>(URLS.RESERVATION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Reservation enregistré avec succès')
        }),
        catchError(this.handleError<IReservation>('postReservation', {}))
      );
  }

  /**
   * Modifier une Reservation.
   * @param item l'objet concerné
   */
  putReservation(item: IReservation) {
    return this.http.put<IReservation>(URLS.RESERVATION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Reservation modifié avec succès')
        }),
        catchError(this.handleError<IReservation>('putReservation', {}))
      );
  }

  /**
   * Supprimer une Reservation.
   * @param item l'objet concerné
   */
  deleteReservation(item: IReservation) {
    return this.http.delete<string>(`${URLS.RESERVATION}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Reservation supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteReservation', ''))
      );
  }

  /**
   * Récupérer la liste des Clients.
   */
  fetchClients() {
    return this.http.get<IClient[]>(URLS.CLIENT)
      .pipe(
        catchError(this.handleError<IClient[]>('fetchClients', []))
      );
  }


  /**
   * Enregistrer un Client.
   * @param item l'objet concerné
   */
  postClient(item: IClient) {
    return this.http.post<IClient>(URLS.CLIENT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Client enregistré avec succès')
        }),
        catchError(this.handleError<IClient>('postClient', {}))
      );
  }

  /**
   * Modifier une Client.
   * @param item l'objet concerné
   */
  putClient(item: IClient) {
    return this.http.put<IClient>(URLS.CLIENT, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Client modifié avec succès')
        }),
        catchError(this.handleError<IClient>('putClient', {}))
      );
  }

  /**
   * Supprimer une Client.
   * @param item l'objet concerné
   */
  deleteClient(item: IClient) {
    return this.http.delete<string>(`${URLS.CLIENT}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Client supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteClient', ''))
      );
  }

  /**
   * Récupérer la liste des Professions.
   */
  fetchProfessions() {
    return this.http.get<IProfession[]>(URLS.PROFESSION)
      .pipe(
        catchError(this.handleError<IProfession[]>('fetchProfessions', []))
      );
  }


  /**
   * Enregistrer un Profession.
   * @param item l'objet concerné
   */
  postProfession(item: IProfession) {
    return this.http.post<IProfession>(URLS.PROFESSION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Profession enregistré avec succès')
        }),
        catchError(this.handleError<IProfession>('postProfession', {}))
      );
  }

  /**
   * Modifier une Profession.
   * @param item l'objet concerné
   */
  putProfession(item: IProfession) {
    return this.http.put<IProfession>(URLS.PROFESSION, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Profession modifié avec succès')
        }),
        catchError(this.handleError<IProfession>('putProfession', {}))
      );
  }

  /**
   * Supprimer une Profession.
   * @param item l'objet concerné
   */
  deleteProfession(item: IProfession) {
    return this.http.delete<string>(`${URLS.PROFESSION}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Profession supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteProfession', ''))
      );
  }

  /**
   * Récupérer la liste des Locataires.
   */
  fetchLocataires() {
    return this.http.get<ILocataire[]>(URLS.LOCATAIRE)
      .pipe(
        catchError(this.handleError<ILocataire[]>('fetchLocataires', []))
      );
  }


  /**
   * Enregistrer un Locataire.
   * @param item l'objet concerné
   */
  postLocataire(item: ILocataire) {
    return this.http.post<ILocataire>(URLS.LOCATAIRE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Locataire enregistré avec succès')
        }),
        catchError(this.handleError<ILocataire>('postLocataire', {}))
      );
  }

  /**
   * Modifier une Locataire.
   * @param item l'objet concerné
   */
  putLocataire(item: ILocataire) {
    return this.http.put<ILocataire>(URLS.LOCATAIRE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Locataire modifié avec succès')
        }),
        catchError(this.handleError<ILocataire>('putLocataire', {}))
      );
  }

  /**
   * Supprimer une Locataire.
   * @param item l'objet concerné
   */
  deleteLocataire(item: ILocataire) {
    return this.http.delete<string>(`${URLS.LOCATAIRE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Locataire supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteLocataire', ''))
      );
  }
  /**
   * Récupérer la liste des Proprietaires.
   */
  fetchProprietaires() {
    return this.http.get<IProprietaire[]>(URLS.PROPRIETAIRE)
      .pipe(
        catchError(this.handleError<IProprietaire[]>('fetchProprietaires', []))
      );
  }


  /**
   * Enregistrer un Proprietaire.
   * @param item l'objet concerné
   */
  postProprietaire(item: IProprietaire) {
    return this.http.post<IProprietaire>(URLS.PROPRIETAIRE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Proprietaire enregistré avec succès')
        }),
        catchError(this.handleError<IProprietaire>('postProprietaire', {}))
      );
  }

  /**
   * Modifier une Proprietaire.
   * @param item l'objet concerné
   */
  putProprietaire(item: IProprietaire) {
    return this.http.put<IProprietaire>(URLS.PROPRIETAIRE, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Proprietaire modifié avec succès')
        }),
        catchError(this.handleError<IProprietaire>('putProprietaire', {}))
      );
  }

  /**
   * Supprimer une Proprietaire.
   * @param item l'objet concerné
   */
  deleteProprietaire(item: IProprietaire) {
    return this.http.delete<string>(`${URLS.PROPRIETAIRE}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Proprietaire supprimé avec succès')
        }),
        catchError(this.handleError<string>('deleteProprietaire', ''))
      );
  }
  /**
   * Récupérer la liste des Categorys.
   */
  fetchProduits() {
    // @ts-ignore
    return this.http.get<IProduit[]>(URLS.PRODUIT)
      .pipe(
        catchError(this.handleError<IProduit[]>('fetchProduitss', []))
      );
  }

  /**
   * Récupérer la liste des Categorys.
   */
  fetchProduit() {
    // @ts-ignore
    let tp = 'MAISON'
    return this.http.get<IProduit[]>(`${URLS.PRODUCT}/${tp}`,this.httpOptions)
      .pipe(
        catchError(this.handleError<IProduit[]>('fetchProduitss', []))
      );
  }

  /**
   * Récupérer la liste des Photo.
   */
  fetchPhotos(item : IProduit) {
    return this.http.get<IPhoto[]>(`${URLS.PHOTO_PRODUIT}/${item.id}`)
      .pipe(
        catchError(this.handleError<IPhoto[]>('fetchPhotos', []))
      );
  }


  /**
   * Enregistrer un Photo.
   * @param item l'objet concerné
   */
  postPhoto(item: IPhoto) {
    console.log('photo', item)
    return this.http.post<IPhoto>(URLS.PHOTO, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Enregistrement', 'Photo enregistré avec succès')
        }),
        catchError(this.handleError<IPhoto>('postPhoto', {}))
      );
  }

  /**
   * Modifier une Photo.
   * @param item l'objet concerné
   */
  putPhoto(item: IPhoto) {
    return this.http.put<IPhoto>(URLS.PHOTO, item, this.httpOptions)
      .pipe(
        tap(i => {
          this.notificationService.success('Modification', 'Photo modifié avec succès')
        }),
        catchError(this.handleError<IPhoto>('putPhoto', {}))
      );
  }

  /**
   * Supprimer une Photo.
   * @param item l'objet concerné
   */
  deletePhoto(item: IPhoto) {
    return this.http.delete<string>(`${URLS.PHOTO}/${item.id}`, {responseType: 'text' as 'json'})
      .pipe(
        tap(i => {
          this.notificationService.success('Suppression', 'Photo supprimé avec succès')
        }),
        catchError(this.handleError<string>('deletePhoto', ''))
      );
  }


}
