import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layout/admin/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ImmobService} from './shared/services/immob.service';
import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {LoaderInterceptor} from './shared/interceptors/loader.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {AuthServerProvider} from './shared/auth/auth-jwt.service';
import {AuthGuardService} from './shared/auth/auth-guard.service';
import {AccountService} from './shared/auth/account.service';
import {UserRouteAccessService} from './shared/auth/user-route-access-service';
import {LoaderService} from './shared/utils/utils';
import {ConfirmationComponent} from './shared/components/confirmation/confirmation.component';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BreadcrumbsComponent,
    TitleComponent,
    AuthComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModalModule,
    SharedModule,
    ToastrModule.forRoot(),
  ],
  schemas: [ NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA  ],
  providers: [
    AuthGuardService,
    AuthServerProvider,
    ImmobService,
    AccountService,
    UserRouteAccessService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    WINDOW_PROVIDERS,
    LoaderService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationComponent]
})
export class AppModule {
}
