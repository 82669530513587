import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {YLOCALSTORAGE} from './contants';

export const createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'type' &&
                req[key] !== null && req[key] !== undefined) {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach(val => {
                options = options.append('sort', val);
            });
        }
    }
    return options;
};

@Injectable()
export class LoaderService {
    public isLoading = new BehaviorSubject(false);
    public requests = new Subject<Array<HttpRequest<any>>>();

    constructor() {
    }

    setRequests(reqs: HttpRequest<any>[]) {
        this.requests.next(reqs);
    }

    getRequests() {
        return this.requests.asObservable();
    }
}


// Convert bytes array and print resulting pdf file in a new tab from the browser.
export function printPdfFile(bytes) {
    window.open(URL.createObjectURL(new Blob([bytes], {type: 'application/pdf'})), '_blank');
}

export function printExcelFile(bytes) {
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    window.open(URL.createObjectURL(new Blob([bytes], {type: contentType})), '_blank');
}


/**
 * Générer une chaine de caractères aléatoires.
 * @param length
 */
export function randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


/**
 * Service pour gerer le telechargement ou l'impression de fichiers.
 */
@Injectable({providedIn: 'root'})
export class ReportFileService {
    constructor(protected http: HttpClient) {
    }

    /**
     * Method to download file
     * @param {?} contentType
     * @param {?} data
     * @param {?} fileName
     * @return {?}
     */
    downloadBinaryFile(contentType, data, fileName) {
        /** @type {?} */
        const byteCharacters = atob(data);
        /** @type {?} */
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        /** @type {?} */
        const byteArray = new Uint8Array(byteNumbers);
        /** @type {?} */
        const blob = new Blob([byteArray], {
            type: contentType
        });
        /** @type {?} */
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.download = fileName;
        tempLink.target = '_blank';
        tempLink.click();
    }

    downloadPlainTextFile(contentType, bytes, fileName = randomString(12)) {
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(new Blob([bytes], {type: contentType}));
        tempLink.download = fileName;
        tempLink.target = '_blank';
        tempLink.click();
    }


    /**
     * Method to abbreviate the text given.
     * @param {?} text
     * @param {?=} append
     * @return {?}
     */
    abbreviate(text, append = '...') {
        if (text.length < 30) {
            return text;
        }
        return text ? (text.substring(0, 15) + append + text.slice(-10)) : '';
    }

    /**
     * Method to find the byte size of the string provides
     * @param {?} base64String
     * @return {?}
     */
    byteSize(base64String) {
        return this.formatAsBytes(this.size(base64String));
    }

    /**
     * Method to convert the file to base64
     * @param {?} file
     * @param {?} cb
     * @return {?}
     */
    toBase64(file, cb) {
        /** @type {?} */
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            // @ts-ignore
          const targetInString = e.target.result.toString();
            /** @type {?} */
            const base64Data = targetInString.substr(targetInString.indexOf('base64,') + 'base64,'.length);
            cb(base64Data);
        };
        fileReader.readAsDataURL(file);
    }

    /**
     * Method to clear the input
     * @param {?} entity
     * @param {?} elementRef
     * @param {?} field
     * @param {?} fieldContentType
     * @param {?} idInput
     * @return {?}
     */
    clearInputImage(entity, elementRef, field, fieldContentType, idInput) {
        if (entity && field && fieldContentType) {
            if (entity.hasOwnProperty(field)) {
                entity[field] = null;
            }
            if (entity.hasOwnProperty(fieldContentType)) {
                entity[fieldContentType] = null;
            }
            if (elementRef && idInput && elementRef.nativeElement.querySelector('#' + idInput)) {
                elementRef.nativeElement.querySelector('#' + idInput).value = null;
            }
        }
    }

    /**
     * @private
     * @param {?} suffix
     * @param {?} str
     * @return {?}
     */
    endsWith(suffix, str) {
        return str.indexOf(suffix, str.length - suffix.length) !== -1;
    }

    /**
     * @private
     * @param {?} value
     * @return {?}
     */
    paddingSize(value) {
        if (this.endsWith('==', value)) {
            return 2;
        }
        if (this.endsWith('=', value)) {
            return 1;
        }
        return 0;
    }

    /**
     * @private
     * @param {?} value
     * @return {?}
     */
    size(value) {
        return value.length / 4 * 3 - this.paddingSize(value);
    }

    /**
     * @private
     * @param {?} size
     * @return {?}
     */
    formatAsBytes(size) {
        return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
    }

    /**
     * Sets the base 64 data & file type of the 1st file on the event (event.target.files[0]) in the passed entity object
     * and returns a promise.
     *
     * @param {?} event the object containing the file (at event.target.files[0])
     * @param {?} entity the object to set the file's 'base 64 data' and 'file type' on
     * @param {?} field the field name to set the file's 'base 64 data' on
     * @param {?} isImage boolean representing if the file represented by the event is an image
     * @return {?} a promise that resolves to the modified entity if operation is successful, otherwise rejects with an error message
     */
    setFileData(event, entity, field, isImage) {
        return new Promise((resolve, reject) => {
            if (event && event.target && event.target.files && event.target.files[0]) {
                /** @type {?} */
                const file = event.target.files[0];
                if (isImage && !/^image\//.test(file.type)) {
                    reject(`File was expected to be an image but was found to be ${file.type}`);
                } else {
                    this.toBase64(file, (base64Data) => {
                        entity[field] = base64Data;
                        entity[`${field}ContentType`] = file.type;
                        resolve(entity);
                    });
                }
            } else {
                reject(`Base64 data was not set as file could not be extracted from passed parameter: ${event}`);
            }
        });
    }

}

/**
 * Deep copy an objet.
 * @param obj
 */
export function deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepCopy(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
}

/**
 * Déconnexion.
 */
export function clearLoginLocalStorage($localStorage, $sessionStorage): Observable<any> {
    return new Observable(observer => {
        console.log('Token name :', YLOCALSTORAGE.TOKEN_NAME)
        $localStorage.clear(YLOCALSTORAGE.TOKEN_NAME);
        $sessionStorage.clear(YLOCALSTORAGE.TOKEN_NAME);
        observer.complete();
    });
}
