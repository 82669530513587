import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {URLS, YLOCALSTORAGE} from '../utils/contants';
import {clearLoginLocalStorage} from '../utils/utils';


@Injectable({providedIn: 'root'})
export class AuthServerProvider {
  constructor(private http: HttpClient, private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService) {
  }

  getToken(): string {
    return this.$localStorage
      .retrieve(YLOCALSTORAGE.TOKEN_NAME) || this.$sessionStorage
      .retrieve(YLOCALSTORAGE.TOKEN_NAME);
  }

  login(credentials): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe
    };
    console.log('data', data);
    return this.http.post(URLS.AUTH, data, {observe: 'response'})
      .pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp): string {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, credentials.rememberMe);
        return jwt;
      }
    }
  }

  storeAuthenticationToken(jwt, rememberMe): any {
    if (rememberMe) {
      this.$localStorage.store(YLOCALSTORAGE.TOKEN_NAME, jwt);
    } else {
      this.$sessionStorage.store(YLOCALSTORAGE.TOKEN_NAME, jwt);
      console.log("session retrieve", this.$sessionStorage.retrieve(YLOCALSTORAGE.TOKEN_NAME));
    }
  }

  /**
   * Déconnexion.
   */
  logout(): Observable<any> {
    return clearLoginLocalStorage(this.$localStorage, this.$sessionStorage)
  }
}

