import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {AdminComponent} from "./layout/admin/admin.component";
import {AuthComponent} from "./layout/auth/auth.component";
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'authentication',
    pathMatch: 'full'
  },
  {
    path: 'authentication',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
  },
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
       {
        path: "",
        loadChildren: () => import("./pages/dashboard/dashboard-default/dashboard-default.module").then((m) => m.DashboardDefaultModule),
      },
      {
        path: "utilisateur",
        loadChildren: () => import("./pages/utilisateurs/utilisateur.module").then((m) => m.UtilisateurModule),
      },
      {
        path: "parametre",
        loadChildren: () => import("./pages/parametres/parametre.module").then((m) => m.ParametreModule),
      },
       {
        path: "maison",
        loadChildren: () => import("./pages/maison/maison.module").then((m) => m.MaisonModule),
      },
       {
        path: "voiture",
        loadChildren: () => import("./pages/voiture/voiture.module").then((m) => m.VoitureModule),
      },
       {
        path: "location",
        loadChildren: () => import("./pages/location/location.module").then((m) => m.LocationModule),
      },
       {
        path: "reservation",
        loadChildren: () => import("./pages/reservation/reservation.module").then((m) => m.ReservationModule),
      },
       {
        path: "client",
        loadChildren: () => import("./pages/client/client.module").then((m) => m.ClientModule),
      },
      {
        path: "basic",
        loadChildren: () => import("./pages/ui-elements/basic/basic.module").then((m) => m.BasicModule),
      }, {
        path: "notifications",
        loadChildren: () => import("./pages/ui-elements/advance/notifications/notifications.module").then((m) => m.NotificationsModule),
      }, {
        path: "bootstrap-table",
        loadChildren: () => import("./pages/ui-elements/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module").then((m) => m.BasicBootstrapModule),
      }, {
        path: "map",
        loadChildren: () => import("./pages/map/google-map/google-map.module").then((m) => m.GoogleMapModule),
      }, {
        path: "user",
        loadChildren: () => import("./pages/user/profile/profile.module").then((m) => m.ProfileModule),
      }, {
        path: "simple-page",
        loadChildren: () => import("./pages/simple-page/simple-page.module").then((m) => m.SimplePageModule),
      },
    ],
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
