import {IRole} from './role';
import {IProfil} from './profil';
import {IAdresse} from './adresse';

export interface IUser {
  id?: string;
  cnib?: string;
  username?: string;
  password?: string;
  nom?: string;
  prenom?: string;
  email?: string;
  telephone?: string;
  locked?: boolean;

  activationKey?: string;
  resetKey?: string;

  idProfil?: string;
  profil?: IProfil;
  libelle?: string;
  roles?: IRole[];

  createdBy?: string;
  createdDate?: any;
  lastModifiedDate?: any;
  /**
   * Date de la dernière connexion.
   */
  lastConnexionDate?: any;
  sexe?: string;
  idEntite?: number;
  libelleEntite?: string;
  libelleProfil?: string;
  matricule?: string;
  direction?: string;
  service?: string;
}


export class User implements IUser {
  id = '';
  cnib = '';
  username = '';
  password = '';
  nom = '';
  prenom = '';
  email = '';
  telephone = '';
  locked = false;

  activationKey = '';
  resetKey = '';

  idProfil = '';
  profil:any = null;
  libelle = null;

  ceb = null;
  nomCeb = null;

  createdBy?: string = null;
  createdDate?: any = null;
  lastModifiedDate?: any = null;
  idEntite = null;
  libelleEntite = null;
  sexe = null;

  /**
   * Date de la dernière connexion.
   */
  lastConnexionDate = null;
  matricule = null;
  direction = null;
  service = null;
}
