/**
 * Correspond au type de rapports.
 */
export enum ETypeRapport {
    DUMMY = 'DUMMY'
}

export enum ECodeParametre {
    MAIL_NOTIF_RAPPORT = 'MAIL_NOTIF_RAPPORT'
}


/** Liste des roles pour accéder aux menus.*/
export enum YlAuthority {
    CONNECT = 'IMMOB_CONNECT',
    ADMIN = 'IMMOB_ADMIN',
    CEB = 'SSAP_CEB',
    CENTRE = 'SSAP_CENTRE',
    ACTEUR = 'SSAP_ACTEUR',
    APPRENANT = 'SSAP_APPRENANT',
    AIDE_PEDAGOGIQUE = 'SSAP_AIDE_PEDAGOGIQUE',
    DOCUMENT_DIDACTIQUE = 'SSAP_DOCUMENT_DIDACTIQUE',
    ANNEE_SCOLAIRE = 'SSAP_ANNEE_SCOLAIRE',
    NIVEAU_CLASSE = 'SSAP_NIVEAU_CLASSE',
    SUIVIS_CURSUS = 'SSAP_SUIVI_CURSUS',
    RAISON = 'SSAP_RAISON',
    LANGUE = 'SSAP_LANGUE',
    FORMATION = 'SSAP_FORMATION',
    INDICATEUR = 'SSAP_CREATION_VARIABLE_INDICATEUR',
    GROUPE = 'SSAP_GROUPE',
    FICHE = 'SSAP_FICHE',
    EQUIPEMENT = 'SSAP_EQUIPEMENT',
    ECOLE = 'SSAP_ECOLE',
    TYPE_HANDICAP = 'SSAP_TYPE_HANDICAP',
    LOCALITE = 'SSAP_LOCALITE',
    ONG = 'SSAP_ONG',
    PTF = 'SSAP_PARTENAIRE_TECH_FINANCIER',
    REPORTING = 'SSAP_RAPPORT',
    USER = 'SSAP_UTILISATEUR',
    PROFIL = 'SSAP_PROFIL',
    PARTENAIRE = 'SSAP_PARTENAIRE',
    RAPPORT = 'SSAP_RAPPORT',
    TYPE_LOCALITE = 'SSAP_TYPE_LOCALITE',
    MESSAGE = 'SSAP_MESSAGE'
}



