import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService) {

    }

    /**
     * Afficher une alerte.
     * @param type type d'alerte
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    alert(type: 'info' | 'warn' | 'error' = 'info', title: string, msg: string) {
        switch (type) {
            case 'error':
                this.error(title, msg);
                break;
            case 'info':
                this.info(title, msg);
                break;
            case 'warn':
                this.warning(title, msg);
                break;
        }
    }

    /**
     * Afficher un message d'information.
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    info(title: string, msg: string) {
        this.toastr.show('',
            msg,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-info alert-with-icon',
                positionClass: 'toast-top-right'
            }
        );
    }

    /**
     * Afficher un message de réussite/succès.
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    success(title: string, msg: string) {
        this.toastr.show('',
            msg,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-success alert-with-icon',
                positionClass: 'toast-top-right'
            }
        );
    }

    /**
     * Afficher un message d'avertissement.
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    warning(title: string, msg: string) {
        this.toastr.show(
            '<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
            msg,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-warning alert-with-icon',
                positionClass: 'toast-top-right'
            }
        );
    }

    /**
     * Afficher un message d'erreur.
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    error(title: string, msg: string) {
        this.toastr.show(
            '<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
            msg,
            {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-danger alert-with-icon',
                positionClass: 'toast-top-right'
            }
        );
    }

    /**
     * Afficher un message d'information static au milieu
     * @param type type de l'alerte
     * @param title titre du message
     * @param msg contenu de l'alert
     */
    pinned(type: 'info' | 'warn' | 'error' = 'info', title: string, msg: string) {
        switch (type) {
            case 'error':
                this.toastr.error(msg, title, {
                    positionClass: 'toast-top-full-width',
                    tapToDismiss: false,
                    timeOut: 50000,
                    disableTimeOut: true,
                    closeButton: true
                });
                break;
            case 'info':
                this.toastr.info(msg, title, {
                    positionClass: 'toast-top-full-width',
                    tapToDismiss: false,
                    timeOut: 50000,
                    disableTimeOut: true,
                    closeButton: true
                });
                break;
            case 'warn':
                this.toastr.warning(msg, title, {
                    positionClass: 'toast-top-full-width',
                    tapToDismiss: false,
                    timeOut: 50000,
                    disableTimeOut: true,
                    closeButton: true
                });
                break;
        }

    }

}
