export class URLS {
  /**
   * URL de base ou la racine.
   */
  public static BASE_URL = '/immob';
  public static AUTH = URLS.BASE_URL + '/authenticate';
  public static USER = URLS.BASE_URL + '/users';
  public static UPDATE_USER_PASSWORD = URLS.BASE_URL + '/change-password';
  public static ROLE = URLS.BASE_URL + '/roles';
  public static TYPE_PIECE = URLS.BASE_URL + '/type-pieces';
  public static ENTITE = URLS.BASE_URL + '/entites';
  public static PROFIL = URLS.BASE_URL + '/profils';
  public static CURRENT_USER_INFO = URLS.USER + '/current';
  public static DASHBOARD = URLS.BASE_URL + '/dashboard';
  public static DASHBOARD_INFOS = URLS.BASE_URL + '/dashboard-infos';
  public static DASHBOARD_INFOS_SUP = URLS.BASE_URL + '/dashboard-infos-supprimer';
  public static REPORTING = URLS.BASE_URL + '/reporting';
  public static LOG = URLS.BASE_URL + '/log';
  public static GROUPE = URLS.BASE_URL + '/groupes';
  public static CATEGORY = URLS.BASE_URL + '/categories';
  public static CATEGORY_MAISON = URLS.BASE_URL + '/categorie-maisons';
  public static CATEGORY_VOITURE = URLS.BASE_URL + '/categorie-voitures';
  public static LOCALITE = URLS.BASE_URL + '/localites';
  public static LOCATAIRE = URLS.BASE_URL + '/locataires';
  public static LOCATION = URLS.BASE_URL + '/locations';
  public static PRODUIT = URLS.BASE_URL + '/produits';
  public static PRODUCT = URLS.BASE_URL + '/products';
  // public static VOITURE = URLS.BASE_URL + '/produit_voitures';
  public static PROPRIETAIRE = URLS.BASE_URL + '/proprietaires';
  public static CLIENT = URLS.BASE_URL + '/clients';
  public static RESERVATION = URLS.BASE_URL + '/reservations';
  public static CONDITION = URLS.BASE_URL + '/conditions';
  public static PROFESSION = URLS.BASE_URL + '/professions';
  public static PHOTO = URLS.BASE_URL + '/photos';
  public static PHOTO_PRODUIT = URLS.BASE_URL + '/photos-produit';

  public static PARAMETRE = URLS.BASE_URL + '/parametres';


}

/**
 * Constantes des variables stockées dans
 * le navigateur en local.
 */
export class YLOCALSTORAGE {
  public static TOKEN_NAME = 'immob-auth-token';
  public static PREVIOUS_URL = 'immob-previous-url';
}
