import {Injectable} from '@angular/core';
import {YlAuthority} from '../model/enumerations';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
  authorities?: YlAuthority[];
  visible?: boolean;
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  authorities?: YlAuthority[];
  visible?: boolean;
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

 const MENUITEMS: Menu[] = [
  {
    label: 'MEMU',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home',
        authorities: [YlAuthority.ADMIN]
      },
      {
        state: 'maison',
        short_label: 'm',
        name: 'Maison',
        type: 'link',
        icon: 'ti-crown',
        authorities: [YlAuthority.ADMIN]
      },
      {
        state: 'voiture',
        short_label: 'v',
        name: 'Voiture',
        type: 'link',
        icon: 'ti-car',
        authorities: [YlAuthority.ADMIN]
      },
      {
        state: 'demenagement',
        short_label: 'v',
        name: 'Démenagement',
        type: 'link',
        icon: 'ti-truck',
        authorities: [YlAuthority.ADMIN]
      },
      {
        state: 'location',
        short_label: 'lo',
        name: 'Location',
        type: 'link',
        icon: 'ti-direction-alt',
        authorities: [YlAuthority.ADMIN]
      },
      {
        state: 'reservation',
        short_label: 'lo',
        name: 'Réservation',
        type: 'link',
        icon: 'ti-palette'
      },
      {
        state: 'client',
        short_label: 'C',
        name: 'Client',
        type: 'link',
        icon: 'ti-id-badge'
      }
      ,
      {
        state: 'utilisateur',
        short_label: 'B',
        name: 'Utilisateurs',
        type: 'sub',
        icon: 'ti-user',
        children: [
          {
            state: 'profil',
            name: 'Profils'
          },
          {
            state: 'user',
            name: 'Utilisateurs'
          }
        ]
      },
      {
        state: 'parametre',
        short_label: 'B',
        name: 'Paramètre',
        type: 'sub',
        icon: 'ti-settings',
        children: [
          {
            state: 'categorie',
            name: 'Catégories'
          },
          {
            state: 'localite',
            name: 'Localités'
          },
          {
            state: 'locataire',
            name: 'Locataires'
          },
          {
            state: 'profession',
            name: 'Professions'
          },
          {
            state: 'proprietaire',
            name: 'Propriétaires'
          }
        ]
      },
      // {
      //   state: 'notifications',
      //   short_label: 'n',
      //   name: 'Notifications',
      //   type: 'link',
      //   icon: 'ti-crown'
      // },
      // {
      //   state: 'login',
      //   short_label: 'n',
      //   name: 'Login',
      //   type: 'link',
      //   icon: 'ti-crown'
      // },
    ],
  },
  // {
  //   label: 'Tables',
  //   main: [
  //     {
  //       state: 'bootstrap-table',
  //       short_label: 'B',
  //       name: 'Bootstrap Table',
  //       type: 'link',
  //       icon: 'ti-receipt'
  //     }
  //   ]
  // },
  // {
  //   label: 'Map And Extra Pages ',
  //   main: [
  //     {
  //       state: 'map',
  //       short_label: 'M',
  //       name: 'Maps',
  //       type: 'link',
  //       icon: 'ti-map-alt'
  //     },
  //     {
  //       state: 'authentication',
  //       short_label: 'A',
  //       name: 'Authentication',
  //       type: 'sub',
  //       icon: 'ti-id-badge',
  //       children: [
  //         {
  //           state: 'login',
  //           type: 'link',
  //           name: 'Login',
  //           target: true
  //         }, {
  //           state: 'registration',
  //           type: 'link',
  //           name: 'Registration',
  //           target: true
  //         }
  //       ]
  //     },
  //     {
  //       state: 'user',
  //       short_label: 'U',
  //       name: 'User Profile',
  //       type: 'link',
  //       icon: 'ti-user'
  //     }
  //   ]
  // },
  // {
  //   label: 'Other',
  //   main: [
  //     {
  //       state: '',
  //       short_label: 'M',
  //       name: 'Menu Levels',
  //       type: 'sub',
  //       icon: 'ti-direction-alt',
  //       children: [
  //         {
  //           state: '',
  //           name: 'Menu Level 2.1',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.2',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.2',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.3',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.4',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.2',
  //               target: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       state: 'simple-page',
  //       short_label: 'S',
  //       name: 'Simple Page',
  //       type: 'link',
  //       icon: 'ti-layout-sidebar-left'
  //     }
  //   ]
  // }, {
  //   label: 'Support',
  //   main: [
  //     {
  //       state: 'Upgrade To Pro',
  //       short_label: 'U',
  //       external: 'https://codedthemes.com/item/guru-able-admin-template/',
  //       name: 'Upgrade To Pro',
  //       type: 'external',
  //       icon: 'ti-layout-list-post',
  //       target: true
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
