import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {URLS} from '../utils/contants';
import {YlAuthority} from '../model/enumerations';
import {IUser} from '../model/user';


@Injectable({providedIn: 'root'})
export class AccountService {
  private userIdentity: any;
  private authenticated = false;
  /**
   * L'ID du CDE, de l'utilisateur.
   */
  private idEntite = null;
  private authenticationState = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  fetchCurrentUserInfo(): Observable<HttpResponse<IUser>> {
    return this.http.get<IUser>(URLS.CURRENT_USER_INFO, {observe: 'response'});
  }

  hasAnyAuthority(authorities: string[]): boolean {
    let result = false;
     console.log('auth ==',this.authenticated)
     console.log('User ==',this.userIdentity)
     console.log('Role user ==',this.userIdentity.profil.roles)
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.profil.roles) {
      result = false;
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < authorities.length; i++) {
        // console.log('Role user ==',this.userIdentity.profil.roles)
        // @ts-ignore
        if (this.userIdentity.profil.roles.map(r => r.code).includes(authorities[i])) {
          result = true;
        }
      }
    }
    return result;
  }

  /**
   * Vérifie si l'utilisateur possède au moins, un de ces rôles.
   * @param authorities .
   */
  hasAnyKfkAuthority(authorities: YlAuthority[] | undefined): boolean {
    // @ts-ignore
    if (authorities && authorities.length > 0) {

      return this.hasAnyAuthority(authorities.map(i => {
        // console.log('autorities value ===',i);
        return i.toString()
      }));
    }
    return true;
  }

  /**
   * Vérifie si l'utilisateur a un rôle précis.
   * @param authority .
   */
  hasKfkAuthority(authority: YlAuthority): boolean {
    if (authority) {
      return this.hasAnyAuthority([authority.toString()]);
    }
    return false;
  }


  identity(force?: boolean): Promise<any> {
    if (force) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this.fetchCurrentUserInfo()
      .toPromise()
      .then(response => {
        // @ts-ignore
        const account = response.body;
        if (account) {
          this.userIdentity = account;
          this.authenticated = true;
          // this.idEntite = account.idEntite;
        } else {
          this.userIdentity = null;
          this.authenticated = false;
          this.idEntite = null;
        }
        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      })
      .catch(err => {
        this.userIdentity = null;
        this.authenticated = false;
        this.idEntite = null;
        this.authenticationState.next(this.userIdentity);
        return null;
      });
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  // getCurrentEntiteId(): number {
  //     return this.idEntite;
  // }

  logout() {
    this.userIdentity = null;
    this.authenticated = false;
    // this.idEntite = null;
    this.authenticationState.next(this.userIdentity);
  }
}
